<template>
    <div>
        <LayoutBreadcrumbs :layers="breadcrumbs" text-color="text-gray-900" />
        <main class="blog-category-index-page">
            <div>
                <ul class="blog-category-index-page__wrapper">
                    <li v-for="(category, index) in info.data.categories" :key="index">
                        <LayoutChip :path="`/${$lang.routes.blog}/`" :info="category" />
                    </li>
                </ul>
            </div>

            <template v-if="data.articles.length">
                <div class="blog-posts-3">
                    <div class="blog-posts-3__head">
                        <h2 class="blog-posts-3__title">
                            {{ $lang.pages.blogCategory.all_articles_about }}
                            <span class="blog-posts-3__title-category">{{
                                info.data.blogCategory.name
                            }}</span>
                        </h2>
                    </div>
                    <ul class="blog-posts-3__wrapper">
                        <li v-for="(article, key) in data.articles" :key="key" class="post">
                            <BCardBasic :post="article" :category="$route.params.category as string" />
                        </li>
                    </ul>
                </div>
            </template>
            <MiscNoResults v-else :title="$lang.pages.blogCategory.category_no_contents" mode="megaphone" />
        </main>
    </div>
</template>

<script lang="ts" setup>
import type { Api } from '~~/global'

const { $lang } = useNuxtApp()
const Route = useRoute()
const { buildHeaders, baseURL, endpoints } = useApiConfig()
const getCategory = (page?: number) =>
    $fetch<Api.Responses.Pages.ArticlesByCategory>(
        endpoints.pages.articlesByCategory.path.replace('_SLUG_', Route.params.category as string),
        {
            headers: buildHeaders(),
            method: 'GET',
            baseURL,
            params: {
                page: page || 1,
                length: 1000,
            },
        },
    )

const { data: responseData, error } = await useAsyncData('blog-index-data', () => {
    return getCategory().catch((e) => e.data)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}
const info = ref(responseData.value)

const pagination = reactive({
    show: info.value.data.articles.meta.last_page !== info.value.data.articles.meta.current_page,
    loading: false,
})

const data = reactive({
    articles: [...info.value.data.articles.data],
})

const title = $lang.pages.blogCategory.articles_about.replace(
    '{{category}}',
    info.value.data.blogCategory.name,
)
const description = $lang.pages.blogCategory.all_articles.replace(
    '{{category}}',
    info.value.data.blogCategory.name,
)

const {
    public: { origin },
} = useRuntimeConfig()

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const breadcrumbs = computed(() => {
    return [
        {
            path: '/',
            title: $lang.pages.blogCategory.home,
        },
        {
            path: `/${$lang.routes.blog}`,
            title: $lang.pages.blogCategory.blog,
        },
        {
            title: info.value.data.blogCategory.name || '',
        },
    ]
})
</script>

<style lang="postcss" scoped>
.blog-category-index-page {
    @apply container mb-12 mt-10 space-y-10;
    &__wrapper {
        @apply grid grid-cols-2 gap-4 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6;
    }
    .blog-posts-3 {
        @apply space-y-5;
        &__head {
            @apply flex justify-between;
        }
        &__title {
            @apply text-xl font-semibold text-gray-800;
            &-category {
                @apply text-site-primary;
            }
        }
        &__wrapper {
            @apply grid grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-3;
        }
    }
}
</style>
